<template>
  <div>
    <label class="bold-text" v-if="title">{{ title }}</label>
    <label class="bold-text" v-else>Seleziona Comune</label>
    <v-select
        label="comune"
        :options="options"
        v-model="select"
        menu-props="auto"
        @search="onSearchComuni"
        @input="sendEmit"
    >
      <template slot="selected-option" slot-scope="option">
        {{ option.comune }} - {{ option.cap }} ({{ option.provincia }})
      </template>
      <template slot="option" slot-scope="option">
        {{ option.comune }} - {{ option.cap }} ({{ option.provincia }})
      </template>
      <span slot="no-options">Scrivi un nome di un comune</span>
    </v-select>
    <br>
  </div>
</template>

<script>


export default {
  data: function () {
    return {
      select: [],
      options: [],
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.select = this.selected;
      },
      type: {
        immediate: true,
      },
      title: {
        immediate: true,
      }
    }
  },
  methods: {
    sendEmit(value) {
      this.$emit("getComune", value);
    },
    onSearchComuni(search, loading) {
      if (search.length) {
        loading(true);

         axios
          .get(`/dashboard/api/v0/comuni/listfrequently?comune__icontains=${escape(search)}`)
          .then((response) => {
            if (response.status === 200) {
              this.options = response.data.results;
              loading(false);
            }
          }, (error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    if (this.selected === null) {
      axios
          .get('/dashboard/api/v0/comuni/listfrequently')
          .then((response) => {
            if (response.status === 200) {
              this.options = response.data.results;
            }
          }, (error) => {
            console.log(error);
          });
    }
  },
  props: {
    selected: {
      type: Object,
      default: []
    },
    type: {
      type: String,
    },
    title: {
      type: String
    }
  },
  name: "SingleComune"
}
</script>

<style>
label.bold-text {
  font-weight: 700;
}
</style>
